import React from 'react'
import { PageProps, graphql } from 'gatsby'
import { IStrapiSectionBackground, IStrapiButton, IStrapiCase, IStrapiFile, IStrapiGridItemSize, IStrapiGridSpacing, IStrapiImage, IStrapiImagePosition, IStrapiImageSize, IStrapiListStyle, IStrapiMixMode, IStrapiImageMobileBehavior, IStrapiPadding, IStrapiImagesCarouselTemplate, IStrapiSeo } from '../types/strapi'
import DefaultSection, { IDefaultSectionData } from '../components/page-sections/default-section'
import SectionLayout from '../layouts/section-layout/SectionLayout'
import ListSection, { IListSectionData } from '../components/page-sections/list-section'
import ButtonsSection, { IButtonsSectionData } from '../components/page-sections/buttons-section'
import transformStrapiPadding from '../utils/transformStrapiPadding'
import { Box, Grid, Stack, Typography } from '@mui/material'
import transformStrapiGridSize from '../utils/transformStrapiGridSize'
import Link from '../components/link'
import CasesLocation from '../components/case-location/CaseLocation'
import castStrapiColumnSpacing from '../utils/castStrapiGridSpacing'
import ImagesCarouselSection, { IImagesCarouselSectionData } from '../components/page-sections/carousel-section/images-carousel-section'
import QuotesSection, { IQuotesSectionData } from '../components/page-sections/quotes-section'
import CasesCarouselSection, { ICasesCarouselSectionData } from '../components/page-sections/carousel-section/cases-carousel-section'
import { useIsMobile } from '../hooks/useIsMobile'
import PageSEO from '../components/seo'
import { LazyProvider } from '../contexts/lazy-provider/LazyProvider'
import TabsListSection, { ITabsListSectionData } from '../components/page-sections/tabs-list-section/TabsListSection'
import { SectionsProvider } from '../contexts/sections-provider/SectionsProvider'
import { SectionComponentType } from '../constants/sections'
import MainForSection from '../components/page-sections/main-form-section/MainFormSection'

interface ICaseDataResponse {
  strapiCase: IStrapiCase & {
    seo: IStrapiSeo
  }
}

export const Head: React.FC<PageProps<ICaseDataResponse>> = ({ data, location }) => {
  const { seo, cover } = data.strapiCase

  return (
    <PageSEO
      seo={seo}
      pathname={location.pathname}
      preloadImage={cover}
    />
  )
}

const CasePage = (props: PageProps<ICaseDataResponse>) => {
  const {
    title, description, services, cover,
    customer, challenge,
    solution, solutionList, solutionImages,
    results, resultsList,
    quoteAuthor, quoteAuthorJobTitle, quoteLogo, quoteText,
    techStack, technologiesTabs,
    filePDF, imagePDF,
    caseStudies, casesCarousel,
    mainForm,
  } = props.data.strapiCase

  const getInTouchButton = ctaButton()
  const downloadButton = filePDF ? pdfButton(filePDF, 'outlined') : undefined

  const isMobile = useIsMobile('md')

  return (
    <>
      {title && description && services && (
        <SectionLayout options={{ theme: caseTheme, background: coverBackground(cover, isMobile), paddingTop: IStrapiPadding.LARGE, paddingBottom: IStrapiPadding.EXTRA_LARGE }}>
          <Grid container>
            <Grid item {...transformStrapiGridSize(IStrapiGridItemSize.SIZE_66)}>
              <DefaultSection data={{ title: { component: 'h1', text: title }, description } as IDefaultSectionData} />
              <Box pt={transformStrapiPadding(IStrapiPadding.SMALL)}>
                <ListSection data={{ itemsInRow: 0, listStyle: IStrapiListStyle.LINK, items: services.map(s => ({ content: s.name, link: { url: s.url } })) } as IListSectionData} />
              </Box>
              <Box pt={transformStrapiPadding(IStrapiPadding.NORMAL)}>
                <ButtonsSection data={{ buttons: [getInTouchButton, downloadButton].filter(b => !!b), mobileFullWidth: true } as IButtonsSectionData} />
              </Box>
            </Grid>
          </Grid>
        </SectionLayout>
      )}

      <SectionsProvider sections={[]}>
        <SectionLayout options={{ theme: lightTheme, paddingTop: IStrapiPadding.NORMAL, paddingBottom: IStrapiPadding.SMALL }}>
          <Grid container spacing={castStrapiColumnSpacing(IStrapiGridSpacing.LARGE)}>
            <Grid item {...transformStrapiGridSize(IStrapiGridItemSize.SIZE_66)} order={1}>
              <LazyProvider isLazy>
                <Grid container spacing={castStrapiColumnSpacing(IStrapiGridSpacing.NORMAL)}>

                  {customer && (
                    <Grid item {...transformStrapiGridSize(IStrapiGridItemSize.SIZE_100)}>
                      <DefaultSection data={{ title: { component: 'h2', text: 'Customer' }, description: customer } as IDefaultSectionData} />
                    </Grid>
                  )}
                  {challenge && (
                    <Grid item {...transformStrapiGridSize(IStrapiGridItemSize.SIZE_100)}>
                      <DefaultSection data={{ title: { component: 'h2', text: 'Challenge' }, description: challenge } as IDefaultSectionData} />
                    </Grid>
                  )}
                  {(solution || solutionList) && (
                    <Grid item {...transformStrapiGridSize(IStrapiGridItemSize.SIZE_100)}>
                      <ListSection data={{ title: { component: 'h2', text: 'Solution' }, description: solution, items: solutionList, listStyle: IStrapiListStyle.DOTS_SQUARE, itemsInRow: (solutionList || []).length > 3 ? 2 : 1 } as IListSectionData} />
                    </Grid>
                  )}
                  {solutionImages && Boolean(solutionImages.length) && (
                    <Grid item {...transformStrapiGridSize(IStrapiGridItemSize.SIZE_100)}>
                      <ImagesCarouselSection data={{ strapi_component: SectionComponentType.IMAGES_CAROUSEL_SECTION, options: { theme: caseTheme }, slides: solutionImages, carouselProps: { autoplay: false, loop: true, pagination: false, slidesPerView: 1, creeping: false }, template: IStrapiImagesCarouselTemplate.DEFAULT } as IImagesCarouselSectionData} />
                    </Grid>
                  )}
                  {(results || resultsList) && (
                    <Grid item {...transformStrapiGridSize(IStrapiGridItemSize.SIZE_100)}>
                      <ListSection data={{ title: { component: 'h2', text: 'Results' }, description: results, items: resultsList, listStyle: IStrapiListStyle.FILLED_CHECK, itemsInRow: 1 } as IListSectionData} />
                    </Grid>
                  )}
                </Grid>
              </LazyProvider>
            </Grid>

            <Grid item {...transformStrapiGridSize(IStrapiGridItemSize.SIZE_33)} order={isMobile ? 0 : 2}>
              <Table {...props.data.strapiCase} />
            </Grid>
          </Grid>
        </SectionLayout>

        <LazyProvider isLazy>
          {quoteText && quoteAuthor && quoteAuthorJobTitle && quoteLogo && (
            <SectionLayout options={{ theme: caseTheme, paddingTop: IStrapiPadding.NORMAL, paddingBottom: IStrapiPadding.NORMAL }}>
              <QuotesSection data={{ quote: quoteText, author: quoteAuthor, authorJobTitle: quoteAuthorJobTitle, authorLogo: quoteLogo } as IQuotesSectionData} />
            </SectionLayout>
          )}

          {techStack && technologiesTabs && (
            <SectionLayout options={{ theme: darkTheme, paddingTop: IStrapiPadding.NORMAL, paddingBottom: IStrapiPadding.NORMAL }}>
              <DefaultSection data={{ title: { component: 'h2', text: 'Tech Stack' }, description: techStack } as IDefaultSectionData} />
              <Box mt={{ xs: 6, md: 8, lg: 10, xl: 12 }} />
              <TabsListSection data={{ tabItems: technologiesTabs } as ITabsListSectionData} />
            </SectionLayout>
          )}

          {filePDF && imagePDF && (
            <SectionLayout options={{ theme: pdfTheme, paddingTop: IStrapiPadding.SMALL, paddingBottom: IStrapiPadding.SMALL }}>
              <DefaultSection
                data={{
                  title: { component: 'h2', text: 'Want more details about the project implementation?' },
                  description: 'Download a PDF version with technical data for offline reading',
                  image: imagePDF,
                  imagePosition: IStrapiImagePosition.LEFT,
                  imageSize: IStrapiImageSize.SMALL,
                  mobileBehavior: IStrapiImageMobileBehavior.HIDE_IMAGE,
                } as IDefaultSectionData}
              >
                <Box pt={{ xs: 6, md: 8, lg: 10 }}>
                  <ButtonsSection data={{ buttons: [pdfButton(filePDF, 'contained')] } as IButtonsSectionData} />
                </Box>
              </DefaultSection>
            </SectionLayout>
          )}

          {caseStudies && casesCarousel && (
            <SectionLayout options={{ theme: lightTheme, paddingTop: IStrapiPadding.NORMAL, paddingBottom: IStrapiPadding.SMALL }}>
              <CasesCarouselSection data={{ strapi_component: SectionComponentType.CASES_CAROUSEL_SECTION, options: {}, title: { component: 'h2', text: 'Case Studies' }, description: caseStudies, carouselProps: { autoplay: false, loop: true, pagination: false, slidesPerView: 2, creeping: false }, cases: casesCarousel } as ICasesCarouselSectionData} />
            </SectionLayout>
          )}
        </LazyProvider>
      </SectionsProvider>

      {mainForm && (
        <MainForSection data={mainForm} />
      )}
    </>
  )
}

export default CasePage

export const query = graphql`
  query($id: String!) {
    strapiCase(id: {eq: $id}) {
      ...StrapiCaseFull
    }
  }
`

const lightTheme = {
  backgroundColor: '#FFFFFF',
  primaryTextColor: '#343B4C',
  secondaryTextColor: '#172028',
}

const darkTheme = {
  backgroundColor: '#343B4C',
  primaryTextColor: '#ffffff',
  secondaryTextColor: '#F7F7F7',
}

const pdfTheme = {
  backgroundColor: '#172028',
  primaryTextColor: '#FFFFFF',
  secondaryTextColor: '#FFFFFF',
}

const caseTheme = {
  backgroundColor: '#F2F3F3',
  primaryTextColor: '#343B4C',
  secondaryTextColor: '#343B4C',
}

const ctaButton = (): IStrapiButton => ({
  color: 'primary',
  variant: 'contained',
  size: 'medium',
  newTab: false,
  label: 'Get in touch',
  url: '#cta-footer',
})

const pdfButton = (file: IStrapiFile, variant: 'contained' | 'outlined'): IStrapiButton => ({
  color: 'primary',
  variant: variant,
  size: 'medium',
  newTab: false,
  label: 'Download full version',
  file: file,
})

const coverBackground = (cover?: IStrapiImage, isMobile = true): IStrapiSectionBackground | undefined => {
  if (!cover) {
    return undefined
  }

  return {
    image: cover,
    mobileOpacity: 0.2,
    desktopOpacity: 0.6,
    mixMode: IStrapiMixMode.NORMAL,
    size: isMobile ? 'cover' : 'contain',
    xPosition: 'right',
    yPosition: 'center',
  }
}

const Table = (props: IStrapiCase) => {
  const { websiteType, task, location, duration, industries, team } = props

  return (
    <Stack spacing={4}>
      {websiteType && <TableRow title='Website Type:' content={<Typography variant='link' {...(websiteType.url && { component: Link, to: websiteType.url })}>{websiteType.name}</Typography>} />}
      {task && <TableRow title='Task:' content={<Typography variant='body1'>{task}</Typography>} />}
      {industries && <TableRow title='Business area:' content={industries.map((ind, i) => <Typography key={ind.name} display='block' variant='link' mt={i ? 2 : 0} {...(ind.filterUrl && { component: Link, to: ind.filterUrl })}>{ind.name}</Typography>)} />}
      {location && <TableRow title='Country:' content={<CasesLocation {...location} sx={{ fontWeight: 300 }} />} />}
      {duration && <TableRow title='Duration:' content={<Typography variant='body1'>{duration}</Typography>} />}
      {team && <TableRow title='Team:' content={<Typography variant='body1'>{team}</Typography>} />}
    </Stack>
  )
}

const TableRow = ({ title, content }: { title: string, content: React.ReactNode }) => (
  <Stack direction='row' spacing={4}>
    <Typography component='h3' fontSize={20} fontWeight={600}>{title}</Typography>
    <div>
      {content}
    </div>
  </Stack>
)
